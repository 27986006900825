.desk-available {
  color: #0acf97;
}
.desk-available-afternoon {
  color: #fd7e14;
}
.desk-available-morning {
  color: #ffbc00;
}
.desk-unavailable {
  color: #d23e44;
}
.desk-disabled {
  color: #d1d1d1;
}
.desk-selected-old {
  color: #7dbff8;
}
.desk-selected-new {
  color: #2c8ef8;
}
.always-visible {
  display: block !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: transparent !important;
}
.table td, .table th {
  border-top: none !important;
}

#reservation-map-container {
  width: 100%;
  padding-top: 50%;
  position: relative;
}
@media (max-width: 576px) {
  #reservation-map-container {
    overflow: scroll;
  }
}
@media (min-width: 576px) {
  #reservation-map-container {
    overflow: hidden;
  }
}
#reservation-map {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 576px;
}

.logout {
  display: block;
  width: 100%;
  padding: 0;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
